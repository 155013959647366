/* line 71, ../scss/_mixins.scss */
.clear {
  clear: both;
}

/* line 73, ../scss/_mixins.scss */
.nobr {
  white-space: nowrap;
}

/* Arrows http://codepen.io/kirkas/pen/otqyJ */
/* --------------------------------------------------
:: Mobile Grid
-------------------------------------------------- */
/* Mobile */
@media only screen and (max-width: 767px) {
  /* =Dynamic Widget Grid
  --------------------------------*/
  /* line 12, ../scss/mobile.scss */
  .widget_row {
    width: 100%;
    margin: 0 auto;
    float: left;
    max-width: 980px;
  }

  /* line 19, ../scss/mobile.scss */
  .widget_row .widget {
    min-height: 1px;
    position: relative;
    margin: 20px 0 0 0;
  }

  /* line 25, ../scss/mobile.scss */
  .widget_row .widget:first-child {
    margin-left: 0;
  }

  /* line 27, ../scss/mobile.scss */
  .widget_row .widget_1,
  .widget_row .widget_2,
  .widget_row .widget_3,
  .widget_row .widget_4,
  .widget_row .widget_5,
  .widget_row .widget_6 {
    width: 100%;
  }
}
/* --------------------------------------------------
	:: End Mobile Grid
---------------------------------------------------*/
/* --------------------------------------------------
:: =Mobile
-------------------------------------------------- */
/* =Tablet screens (Portrait  mode)*/
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* line 54, ../scss/mobile.scss */
  #header.isStuck {
    position: relative !important;
  }
  /* line 58, ../scss/mobile.scss */
  #header #site-id {
    margin: 0 20px;
  }
  /* line 60, ../scss/mobile.scss */
  #header .top-news {
    display: none;
  }
  /* line 64, ../scss/mobile.scss */
  #header #site-id img {
    max-width: 250px;
  }
  /* line 68, ../scss/mobile.scss */
  #header .search-form {
    display: none !important;
  }
  /* line 72, ../scss/mobile.scss */
  #header .site-navigation .main_menu > ul > li > a {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* line 79, ../scss/mobile.scss */
  #header.has-trending.has-random .nav-primary,
  #header.has-trending.has-search .nav-primary {
    margin-right: 0;
  }

  /* line 84, ../scss/mobile.scss */
  #header.has-news .breaking-banner,
  #header.has-news .nav-primary {
    margin-left: 0;
  }

  /* line 89, ../scss/mobile.scss */
  #header-nav-extras {
    display: none;
  }

  /* line 93, ../scss/mobile.scss */
  #header,
  .radium-gallery.thumbnails,
  .radium-gallery-wrapper {
    min-width: 0;
  }
  /* line 98, ../scss/mobile.scss */
  #header .page-grid-item,
  .radium-gallery.thumbnails .page-grid-item,
  .radium-gallery-wrapper .page-grid-item {
    width: 46% !important;
  }
  /* line 100, ../scss/mobile.scss */
  #header .page-grid-item .grid-thumb > a,
  #header .page-grid-item .grid-thumb img,
  .radium-gallery.thumbnails .page-grid-item .grid-thumb > a,
  .radium-gallery.thumbnails .page-grid-item .grid-thumb img,
  .radium-gallery-wrapper .page-grid-item .grid-thumb > a,
  .radium-gallery-wrapper .page-grid-item .grid-thumb img {
    height: 200px;
  }

  /* line 106, ../scss/mobile.scss */
  .blog-grid-items .small-thumbs .entry-content-media {
    width: 45%;
  }

  /* line 110, ../scss/mobile.scss */
  .blog-grid-items .small-thumbs .entry-content-media,
  .blog-grid-items .small-thumbs .entry-content-media img {
    max-height: 180px;
  }

  /* line 115, ../scss/mobile.scss */
  .post-prev,
  .post-next {
    display: none;
  }

  /* line 118, ../scss/mobile.scss */
  .content-list-small .entry-header {
    width: 60%;
  }

  /* line 122, ../scss/mobile.scss */
  .about-author .author-desc {
    width: 70%;
  }

  /* line 126, ../scss/mobile.scss */
  .widget_radium_ad_widget .widget-ad a {
    float: none !important;
  }

  /* line 130, ../scss/mobile.scss */
  .widget_radium_post_tabs .tab-hold #tabs > li a {
    font-size: 13px;
  }

  /* line 132, ../scss/mobile.scss */
  .widget_radium_social_fans .fans-home-number span {
    font-size: 58px;
    line-height: 40px;
  }

  /* line 137, ../scss/mobile.scss */
  .featured .widget-buzz-header {
    line-height: 22px;
    padding-top: 15px;
  }

  /* line 142, ../scss/mobile.scss */
  .widget_radium_social_fans .fans-home-number p {
    line-height: 1.2;
  }

  /* line 146, ../scss/mobile.scss */
  #fb-root {
    display: none !important;
  }

  /* To fill the container and nothing else */
  /* line 152, ../scss/mobile.scss */
  .fb_iframe_widget,
  .fb_iframe_widget span,
  .fb_iframe_widget span iframe[style] {
    width: 100% !important;
  }
}
/* =Tablet screens (landscape  mode)*/
/* --------------------------------------------------
:: Mobile Grid
-------------------------------------------------- */
/* Used to alter styles for screens at least 768px wide. This is where the grid changes. */
/* Mobile */
@media only screen and (max-width: 767px) {
  /* line 177, ../scss/mobile.scss */
  .logged-in {
    padding-top: 46px;
  }

  /* line 181, ../scss/mobile.scss */
  #handheld-menu-holder {
    height: 50px;
    border: 1px solid #dfdfdd;
  }

  /* line 186, ../scss/mobile.scss */
  .pace.pace-active {
    display: none;
  }

  /* line 188, ../scss/mobile.scss */
  .left,
  .right {
    float: none !important;
  }

  /* line 193, ../scss/mobile.scss */
  #header {
    position: inherit;
    border-bottom: 1px solid #dfdfdd;
  }
  /* line 197, ../scss/mobile.scss */
  #header .site-navigation {
    margin-top: 0;
  }
  /* line 199, ../scss/mobile.scss */
  #header .site-navigation .nav-primary {
    position: absolute;
    top: 0;
    left: 15px;
    border-top: none;
    border-bottom: none;
  }
  /* line 5, ../scss/_mixins.scss */
  #header .site-navigation .nav-primary:after {
    content: "";
    display: block;
    clear: both;
  }
  /* line 208, ../scss/mobile.scss */
  #header .site-navigation > .row > .columns {
    position: inherit;
  }
  /* line 212, ../scss/mobile.scss */
  #header .site-navigation .search-form {
    margin-left: 0 !important;
    right: auto;
    left: 65px !important;
    width: 67.6% !important;
    z-index: 1 !important;
  }
  /* line 219, ../scss/mobile.scss */
  #header .site-navigation .search-form .container input[type="text"] {
    padding: 10px 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
    border-top: 1px solid #dfdfdd;
    border-bottom: 1px solid #dfdfdd;
    height: 50px !important;
    line-height: 50px !important;
    font-size: 14px !important;
  }
  /* line 235, ../scss/mobile.scss */
  #header .site-id {
    float: none !important;
    width: 100%;
    max-height: 200px;
  }
  /* line 240, ../scss/mobile.scss */
  #header .site-id a {
    display: block;
    text-align: center;
  }
  /* line 247, ../scss/mobile.scss */
  #header .breaking-banner {
    display: none;
  }
  /* line 251, ../scss/mobile.scss */
  #header > .row > .columns > div {
    float: none !important;
    width: 100%;
  }
  /* line 256, ../scss/mobile.scss */
  #header .top-news {
    display: none;
  }
  /* line 260, ../scss/mobile.scss */
  #header .site-header-toolbar {
    border-bottom: none;
  }
  /* line 263, ../scss/mobile.scss */
  #header .site-header-toolbar .columns {
    padding: 0 !important;
  }
  /* line 265, ../scss/mobile.scss */
  #header .site-header-toolbar ul.top-menu {
    text-align: center;
  }
  /* line 268, ../scss/mobile.scss */
  #header .site-header-toolbar ul.top-menu li {
    display: inline-block;
    position: relative;
  }
  /* line 272, ../scss/mobile.scss */
  #header .site-header-toolbar ul.top-menu li:last-child {
    border-right: none;
  }
  /* line 274, ../scss/mobile.scss */
  #header .site-header-toolbar ul.top-menu li .sub-menu {
    left: -25%;
  }
  /* line 276, ../scss/mobile.scss */
  #header .site-header-toolbar ul.top-menu li .sub-menu li:first-child:before {
    right: 50%;
  }
  /* line 284, ../scss/mobile.scss */
  #header .site-header-toolbar .header-tools {
    position: relative;
  }
  /* line 287, ../scss/mobile.scss */
  #header .site-header-toolbar .header-tools ul.sub-menu {
    display: block;
    margin: 0 auto;
  }
  /* line 290, ../scss/mobile.scss */
  #header .site-header-toolbar .header-tools ul.sub-menu li {
    background-color: #22272A;
  }
  /* line 297, ../scss/mobile.scss */
  #header .site-header-toolbar .header-tools .account-item ul.sub-menu .inner {
    width: 234px;
    text-align: left;
  }
  /* line 303, ../scss/mobile.scss */
  #header .site-header-toolbar .header-tools .account-item .user-info-nav-primary ul li {
    display: block;
  }

  /* line 313, ../scss/mobile.scss */
  .site-header-toolbar .menu.right > li:after {
    display: none;
  }

  /* line 317, ../scss/mobile.scss */
  .header-style-default .main_menu > ul > li > a,
  .header-style-default .main_menu > ul > li > a .submenu-link > .menu-title-outer {
    color: white;
  }

  /* line 322, ../scss/mobile.scss */
  .breaking-banner .meta-bar ul {
    height: 51px;
  }

  /* line 326, ../scss/mobile.scss */
  #header-nav-extras {
    height: 50px;
  }

  /* line 330, ../scss/mobile.scss */
  #header-search {
    background-position: 13px -968px;
  }

  /* line 334, ../scss/mobile.scss */
  #header.has-trending.has-random .nav-primary,
  #header.has-trending.has-search .nav-primary,
  #header.has-news .breaking-banner,
  #header.has-news .nav-primary {
    margin-left: 0;
    margin-right: 0;
  }

  /* line 342, ../scss/mobile.scss */
  #header-nav-extras .ribbon-shadow-right {
    display: none;
  }

  /* line 344, ../scss/mobile.scss */
  #header-nav-extras {
    right: 0;
  }

  /* line 346, ../scss/mobile.scss */
  #header.logo-left .site-header-banner,
  #header.logo-right .site-header-banner {
    margin-top: 0;
  }

  /* line 351, ../scss/mobile.scss */
  .top-news .ribbon-shadow-left,
  .top-news > a,
  .top-news-items,
  .breaking-banner .meta-bar-title,
  .breaking-banner .meta-bar ul li,
  #header-random {
    display: none;
  }

  /* line 360, ../scss/mobile.scss */
  #header .site-header-toolbar .top-menu,
  #header .site-header-toolbar div.header-tools {
    float: none;
    width: 100%;
    border-bottom: 1px solid rgba(223, 223, 221, 0.09);
  }
  /* line 5, ../scss/_mixins.scss */
  #header .site-header-toolbar .top-menu:after,
  #header .site-header-toolbar div.header-tools:after {
    content: "";
    display: block;
    clear: both;
  }

  /* line 368, ../scss/mobile.scss */
  .top-news {
    margin-left: 0;
  }

  /* line 372, ../scss/mobile.scss */
  .widget_radium_social_fans .fans-home > div:not(.fans-home-number) {
    float: none;
    display: inline-block;
  }

  /* line 377, ../scss/mobile.scss */
  #header-search .search-remove,
  .top-news,
  .breaking-banner .story {
    height: 50px;
  }

  /* line 383, ../scss/mobile.scss */
  #header-search {
    border-bottom: none;
  }

  /* line 387, ../scss/mobile.scss */
  #header.has-news.has-search .site-navigation .search-form {
    margin-left: 0;
  }

  /* line 391, ../scss/mobile.scss */
  #main-menu .menu-item-object-category .sub-mega-wrap .sub-menu > li.cat-post:after, #main-menu .menu-item-object-category .sub-mega-wrap:not(.single-cat) .sub-menu > li.active, #main-menu .menu-item-object-category .sub-mega-wrap:not(.single-cat) .sub-menu > li:hover, .main_menu > ul > li div ul li li:after, .main_menu > ul > li > a:after, .main_menu > ul > li > ul li:after {
    background-color: transparent !important;
  }

  /* Page Layout */
  /* line 396, ../scss/mobile.scss */
  .sidebar-wrapper.pull-9 {
    right: auto;
  }

  /* line 400, ../scss/mobile.scss */
  .with-sidebar main.push-3 {
    left: auto;
  }

  /* Layout Builder */
  /* line 405, ../scss/mobile.scss */
  .sidebar-wrapper,
  main.sidebar-left,
  main.sidebar-right,
  .with-sidebar main {
    width: 100%;
    border-left: none;
    border-right: none;
  }

  /* line 416, ../scss/mobile.scss */
  .row-wrapper .row .row {
    margin-left: 0;
    margin-right: 0;
  }
  /* line 419, ../scss/mobile.scss */
  .row-wrapper .row .row .columns {
    padding: 0 0;
  }

  /* line 423, ../scss/mobile.scss */
  .post-slider,
  .element-content_slider .slider-wrapper,
  .post-slider li img {
    width: 100%;
    max-height: 250px;
  }

  /* line 430, ../scss/mobile.scss */
  .page-template-page-templatespage-home-php .entry-element-title h3,
  .nav-tabs > li {
    margin-top: 0;
  }
  /* line 433, ../scss/mobile.scss */
  .page-template-page-templatespage-home-php .entry-element-title h3::after,
  .nav-tabs > li::after {
    display: none;
  }

  /* line 437, ../scss/mobile.scss */
  .nav-tabs > li a {
    padding: 5px 8px;
  }

  /* Teaser */
  /* line 445, ../scss/mobile.scss */
  .teaser.teaser-small {
    width: 350px;
    height: 230px;
  }
  /* line 448, ../scss/mobile.scss */
  .teaser.teaser-small .teaser-image,
  .teaser.teaser-small .teaser-overlay {
    width: 350px;
    height: 230px;
  }
  /* line 455, ../scss/mobile.scss */
  .teaser.teaser-medium {
    width: 350px;
    height: 230px;
  }
  /* line 458, ../scss/mobile.scss */
  .teaser.teaser-medium .teaser-image,
  .teaser.teaser-medium .teaser-overlay {
    width: 350px;
    height: 230px;
  }

  /* line 466, ../scss/mobile.scss */
  .horizontal-carousel.post > ul {
    height: 230px;
  }

  /* line 470, ../scss/mobile.scss */
  .recent-posts-carousel li,
  .recent-posts-carousel li .teaser-mini,
  .horizontal-carousel ul > li {
    width: 350px;
  }

  /* line 476, ../scss/mobile.scss */
  .recent-posts-carousel .entry-summary {
    width: 215px;
  }

  /* line 480, ../scss/mobile.scss */
  .reviews-carousel .horizontal-carousel ul > li {
    width: 300px;
  }

  /* line 484, ../scss/mobile.scss */
  .element-radium_video .video-central-carousel .horizontal-carousel-container li.video-central-item {
    width: 150px;
  }

  /* line 488, ../scss/mobile.scss */
  .horizontal-carousel {
    width: auto;
  }

  /* line 492, ../scss/mobile.scss */
  .element-content_slider .slider-wrapper .post-slider .entry-title {
    font-size: 22px;
    margin-bottom: 20px;
  }

  /* Single Post */
  /* line 500, ../scss/mobile.scss */
  #page-header .header {
    border-bottom: 1px solid #dfdfdd;
    padding-bottom: 15px;
    margin-bottom: 10px;
    text-align: center;
  }
  /* line 507, ../scss/mobile.scss */
  #page-header .row > .large-6.columns {
    padding: 0 !important;
  }
  /* line 511, ../scss/mobile.scss */
  #page-header .breadcrumb.right {
    float: none !important;
    text-align: center;
    padding: 5px 15px 0 15px;
  }
  /* line 516, ../scss/mobile.scss */
  #page-header .breadcrumb.right .breadcrumb-inner {
    line-height: 1.5;
  }

  /* line 523, ../scss/mobile.scss */
  #post-side-share {
    display: none !important;
  }

  /* line 525, ../scss/mobile.scss */
  .about-author .inner > h3,
  .about-author .author-desc,
  .about-author .author-avatar {
    width: 100%;
    float: none;
    text-align: center;
  }

  /* line 533, ../scss/mobile.scss */
  .about-author .author-avatar {
    margin-bottom: 15px;
  }

  /* line 535, ../scss/mobile.scss */
  .single main .post .entry-media-wrapper,
  .single main .post .entry-header-wrapper {
    max-width: 100%;
    float: none;
    padding: 15px 15px;
  }

  /* line 542, ../scss/mobile.scss */
  .single main .type-post > .large .entry-media-wrapper,
  .single main .type-post > .large .entry-media-wrapper .entry-content-media {
    min-height: 320px;
  }

  /* line 547, ../scss/mobile.scss */
  .single main .type-post > .large .entry-header-wrapper {
    margin-right: 0;
  }

  /* line 551, ../scss/mobile.scss */
  .single main .entry-rating {
    width: 100%;
    float: none;
    margin: 15px 0;
  }
  /* line 556, ../scss/mobile.scss */
  .single main .entry-rating .user-rating {
    height: 80px;
  }
  /* line 559, ../scss/mobile.scss */
  .single main .entry-rating .user-rating > span {
    display: block;
  }
  /* line 561, ../scss/mobile.scss */
  .single main .entry-rating .user-rating .user-review-rating {
    float: left;
  }
  /* line 566, ../scss/mobile.scss */
  .single main .entry-rating.stars .rating-stars-wrapper {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
  }
  /* line 574, ../scss/mobile.scss */
  .single main .entry-rating.percentage .user-rating .rating-stars-wrapper {
    position: relative;
    width: 100% !important;
    height: 22px;
  }
  /* line 580, ../scss/mobile.scss */
  .single main .entry-rating.percentage .user-rating .rating-stars,
  .single main .entry-rating.percentage .user-rating .rating-stars span {
    height: 22px;
  }
  /* line 585, ../scss/mobile.scss */
  .single main .entry-rating.percentage .user-review-rating {
    float: none;
  }

  /* line 593, ../scss/mobile.scss */
  .single .post-side-share.position-above .icon,
  .single .post-side-share.position-above-below .icon,
  .single .post-side-share.position-below .icon {
    margin-bottom: 15px;
    width: 38px;
  }

  /* line 600, ../scss/mobile.scss */
  .single .post-side-share.position-above .icon .share-network,
  .single .post-side-share.position-above-below .icon .share-network,
  .single .post-side-share.position-below .icon .share-network {
    display: none;
  }

  /* line 606, ../scss/mobile.scss */
  .lightbox-wrapper div.thumbnails a {
    float: none;
    display: block;
  }

  /* line 611, ../scss/mobile.scss */
  #related-posts li {
    width: 50% !important;
    height: 150px !important;
  }

  /* line 616, ../scss/mobile.scss */
  #related-posts li img {
    height: 150px !important;
  }

  /* line 620, ../scss/mobile.scss */
  #related-posts li.one-item,
  #related-posts li.one-item img {
    height: 250px !important;
  }

  /* line 625, ../scss/mobile.scss */
  .entry-rating .long-summary,
  .entry-rating .rating-final-score {
    float: none;
    width: 100%;
    margin-left: 0;
  }

  /* line 632, ../scss/mobile.scss */
  .entry-rating.percentage .user-rating .rating-stars-wrapper {
    position: relative;
    width: 90px;
  }

  /* line 637, ../scss/mobile.scss */
  #commentform #submit {
    width: 100%;
  }

  /* Category Blog */
  /* line 640, ../scss/mobile.scss */
  .blog-grid-items .small,
  .blog-grid-items .small-thumbs .content_wrapper,
  .blog-grid-items .small-thumbs .entry-content-media {
    width: 100%;
    margin-right: 0;
    float: none;
  }

  /* line 648, ../scss/mobile.scss */
  .blog-grid-items .grid_elements {
    border-bottom: 1px solid #dfdfdd;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
  /* line 5, ../scss/_mixins.scss */
  .blog-grid-items .grid_elements:after {
    content: "";
    display: block;
    clear: both;
  }

  /* line 655, ../scss/mobile.scss */
  .blog-grid-items .grid_elements.last {
    padding-left: 0;
  }

  /* line 657, ../scss/mobile.scss */
  .blog-grid-divider {
    display: none;
  }

  /* line 659, ../scss/mobile.scss */
  .blog-grid-items .small-thumbs .content_wrapper {
    padding-bottom: 10px;
  }

  /* line 661, ../scss/mobile.scss */
  #related-posts li .entry-header,
  .content-carousel-element .entry-header,
  .element-content_slider .entry-header {
    bottom: 40px;
  }

  /* line 667, ../scss/mobile.scss */
  .content-list-big .entry-header {
    bottom: 20px;
  }

  /* line 671, ../scss/mobile.scss */
  .review-slide-item .entry-header {
    bottom: 60px;
  }

  /* WooCommerce */
  /* line 676, ../scss/mobile.scss */
  .radium-product-carousel > li,
  .radium-product-carousel > li.product-small {
    width: 100%;
    margin: 0 auto 20px auto;
    text-align: center;
    padding: 0 0 !important;
  }

  /* line 684, ../scss/mobile.scss */
  .woocommerce .product .woocommerce-tabs ul.tabs,
  .woocommerce .product .woocommerce-tabs .columns {
    padding-left: 10px;
    padding-right: 10px;
    border: none;
  }

  /* line 691, ../scss/mobile.scss */
  .woocommerce .product .woocommerce-tabs .panel {
    border: none;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
  }

  /* line 698, ../scss/mobile.scss */
  .woocommerce .product .woocommerce-tabs ul.tabs {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
  }
  /* line 705, ../scss/mobile.scss */
  .woocommerce .product .woocommerce-tabs ul.tabs li {
    margin-left: 0;
    margin-right: 0;
  }

  /* line 712, ../scss/mobile.scss */
  .woocommerce .product .woocommerce-tabs .panel {
    padding-top: 0;
  }

  /* line 714, ../scss/mobile.scss */
  .woocommerce .product div.images div.thumbnails a {
    width: 30.75%;
  }

  /* line 716, ../scss/mobile.scss */
  #builder-container .element-blog_latest .entry-element-title h3 {
    display: none;
  }

  /* line 720, ../scss/mobile.scss */
  .content-list-small .entry-header {
    width: 70%;
  }

  /* line 724, ../scss/mobile.scss */
  .blog-grid-items .small,
  .blog-grid-items .smaller {
    width: 100%;
    float: none;
  }

  /* line 730, ../scss/mobile.scss */
  .blog-grid-items .smaller.first {
    padding-right: 0;
  }

  /* line 734, ../scss/mobile.scss */
  .entry-content-media .post-thumb.zoom-img-in img {
    width: 100%;
    height: auto !important;
  }

  /* line 739, ../scss/mobile.scss */
  .blog-grid-items .entry-content-media {
    height: auto !important;
  }
}
/* Mobile Menu */
@media only screen and (max-width: 767px) {
  /* line 749, ../scss/mobile.scss */
  #menu-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #000;
    opacity: .7;
    filter: alpha(opacity=70);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 90;
  }

  /* line 762, ../scss/mobile.scss */
  .main_menu {
    width: 100%;
    width: 260px;
    position: relative;
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
    -webkit-perspective-origin: 50% 200%;
    -moz-perspective-origin: 50% 200%;
    perspective-origin: 50% 200%;
    z-index: 99;
  }
  /* line 774, ../scss/mobile.scss */
  .main_menu .menu-desc {
    display: none;
  }

  /* line 777, ../scss/mobile.scss */
  .main_menu button {
    border: none;
    width: 50px;
    height: 50px;
    text-indent: -900em;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    outline: none;
    margin: 0 0 0 0;
  }

  /* line 789, ../scss/mobile.scss */
  .main_menu button:after {
    content: '';
    position: absolute;
    width: 60%;
    height: 3px;
    background: black;
    top: 17px;
    left: 20%;
    width: 30px;
    box-shadow: 0 5px 0 black, 0 10px 0 black, 0 15px 0 black;
  }

  /* line 804, ../scss/mobile.scss */
  .main_menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 100%;
  }

  /* line 814, ../scss/mobile.scss */
  .main_menu li {
    position: relative;
  }
  /* line 816, ../scss/mobile.scss */
  .main_menu li .has-icon .icon {
    margin-right: 5px;
  }

  /* line 819, ../scss/mobile.scss */
  .main_menu li a {
    display: block;
    position: relative;
    padding: 0 20px;
    font-size: 16px;
    line-height: 40px;
    font-weight: 300;
    color: #fff;
    outline: none;
    font-size: 14px;
  }

  /* line 831, ../scss/mobile.scss */
  .no-touch .main_menu li a:hover,
  li.cat-post:hover {
    background: rgba(93, 69, 124, 0.1);
  }

  /* line 837, ../scss/mobile.scss */
  .main_menu li.current-menu-item:before {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    width: 260px;
    height: 1px;
    bottom: -1px;
    z-index: 1;
    left: 0;
    background-color: #ff5a00;
  }

  /* line 854, ../scss/mobile.scss */
  .main_menu li.dl-back {
    width: 100%;
    padding: 0;
  }
  /* line 858, ../scss/mobile.scss */
  .main_menu li.dl-back > a {
    padding-left: 22px;
    background: rgba(93, 69, 124, 0.1);
    text-transform: capitalize;
  }

  /* line 866, ../scss/mobile.scss */
  .main_menu li.dl-back:after,
  .main_menu li > a:not(:only-child):after {
    position: absolute;
    top: 0;
    line-height: 40px;
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    content: "\f0da";
  }

  /* line 879, ../scss/mobile.scss */
  .main_menu li.dl-back:after {
    left: 10px;
    color: rgba(255, 255, 255, 0.3);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  /* line 887, ../scss/mobile.scss */
  .main_menu li > a:after {
    right: 10px;
    color: rgba(255, 255, 255, 0.3);
  }

  /* line 892, ../scss/mobile.scss */
  .has-icon .icon {
    height: 40px;
    width: 40px;
    display: none;
  }

  /* line 898, ../scss/mobile.scss */
  .has-icon .menu-title-outer {
    width: 160px;
    height: 40px;
    display: inline-block;
  }

  /* line 904, ../scss/mobile.scss */
  .menu-title {
    height: 40px;
  }

  /* line 908, ../scss/mobile.scss */
  .main_menu a {
    *zoom: 1;
  }

  /* line 910, ../scss/mobile.scss */
  .main_menu a:before,
  .main_menu a:after {
    content: " ";
    display: table;
  }

  /* line 912, ../scss/mobile.scss */
  .main_menu a:after {
    clear: both;
  }

  /* line 914, ../scss/mobile.scss */
  .main_menu a > .menu-title-outer,
  .main_menu a > span > .menu-title-outer {
    color: #fff;
    height: 40px;
    line-height: 40px;
    font-weight: normal;
    text-transform: uppercase;
  }

  /* line 923, ../scss/mobile.scss */
  .main_menu a > .menu-title-outer .menu-title {
    width: 100%;
  }

  /* line 925, ../scss/mobile.scss */
  .sub-indicator {
    width: 40px;
    height: 40px;
    display: block;
    float: right;
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    content: "\f0da";
    color: #fff;
  }

  /* line 940, ../scss/mobile.scss */
  .main_menu .radium_mega {
    margin: 10px 0 0 0;
    position: absolute;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    border-top: 3px solid #ff5a00;
  }
  /* line 954, ../scss/mobile.scss */
  .main_menu .radium_mega:after {
    content: '';
    position: absolute;
    top: -10px;
    left: 10px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #ff5a00;
  }

  /* line 965, ../scss/mobile.scss */
  .main_menu .radium_mega.dl-menu-toggle {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  /* line 971, ../scss/mobile.scss */
  .main_menu .radium_mega.dl-menuopen {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px);
  }

  /* Hide the inner submenus */
  /* line 980, ../scss/mobile.scss */
  .main_menu li .sub-menu,
  .main_menu ul .subcat.sub-cat-list {
    display: none;
  }

  /* line 985, ../scss/mobile.scss */
  .main_menu .radium-mega-div h4 {
    color: #eedd9a;
    padding: 10px 20px;
    margin-bottom: 0;
    border-bottom: 1px solid #363a3d;
    text-shadow: none;
  }

  /* line 993, ../scss/mobile.scss */
  .main_menu .hide-menu-text h4 {
    display: none;
  }

  /* line 995, ../scss/mobile.scss */
  .main_menu .radium-mega-text-block {
    padding: 10px 20px;
  }

  /* line 1000, ../scss/mobile.scss */
  li.cat-post.has-thumbnail,
  li.cat-post.no-thumb {
    padding: 10px 20px;
  }
  /* line 1004, ../scss/mobile.scss */
  li.cat-post.has-thumbnail .subcat-thumbnail,
  li.cat-post.has-thumbnail .subcat-title,
  li.cat-post.no-thumb .subcat-thumbnail,
  li.cat-post.no-thumb .subcat-title {
    float: left;
  }
  /* line 1008, ../scss/mobile.scss */
  li.cat-post.has-thumbnail .subcat-thumbnail a,
  li.cat-post.has-thumbnail .subcat-title a,
  li.cat-post.no-thumb .subcat-thumbnail a,
  li.cat-post.no-thumb .subcat-title a {
    padding: 0;
  }
  /* line 1010, ../scss/mobile.scss */
  li.cat-post.has-thumbnail .subcat-thumbnail span,
  li.cat-post.has-thumbnail .subcat-title span,
  li.cat-post.no-thumb .subcat-thumbnail span,
  li.cat-post.no-thumb .subcat-title span {
    display: none;
  }
  /* line 1013, ../scss/mobile.scss */
  li.cat-post.has-thumbnail .subcat-thumbnail,
  li.cat-post.no-thumb .subcat-thumbnail {
    padding-top: 5px;
    width: 50px;
  }
  /* line 1017, ../scss/mobile.scss */
  li.cat-post.has-thumbnail .subcat-thumbnail a,
  li.cat-post.no-thumb .subcat-thumbnail a {
    padding: 0 0;
  }
  /* line 1019, ../scss/mobile.scss */
  li.cat-post.has-thumbnail .subcat-thumbnail a:hover,
  li.cat-post.no-thumb .subcat-thumbnail a:hover {
    background-color: transparent;
  }
  /* line 1022, ../scss/mobile.scss */
  li.cat-post.has-thumbnail .subcat-thumbnail .entry-meta,
  li.cat-post.has-thumbnail .subcat-thumbnail .entry-extras,
  li.cat-post.has-thumbnail .subcat-thumbnail .entry-review,
  li.cat-post.no-thumb .subcat-thumbnail .entry-meta,
  li.cat-post.no-thumb .subcat-thumbnail .entry-extras,
  li.cat-post.no-thumb .subcat-thumbnail .entry-review {
    display: none;
  }
  /* line 1030, ../scss/mobile.scss */
  li.cat-post.has-thumbnail .subcat-title,
  li.cat-post.no-thumb .subcat-title {
    width: 170px;
    padding: 0 0 0 10px;
    border-bottom: none;
  }
  /* line 1035, ../scss/mobile.scss */
  li.cat-post.has-thumbnail .subcat-title a,
  li.cat-post.no-thumb .subcat-title a {
    line-height: inherit;
  }
  /* line 1037, ../scss/mobile.scss */
  li.cat-post.has-thumbnail .subcat-title a:hover,
  li.cat-post.no-thumb .subcat-title a:hover {
    background-color: transparent;
  }

  /*
  When a submenu is openend, we will hide all li siblings.
  For that we give a class to the parent menu called "dl-subview".
  We also hide the submenu link.
  The opened submenu will get the class "dl-subviewopen".
  All this is done for any sub-level being entered.
  */
  /* line 1050, ../scss/mobile.scss */
  .radium_mega.dl-subview li.dl-subviewopen {
    border-bottom: none;
  }

  /* line 1052, ../scss/mobile.scss */
  .radium_mega.dl-subview li,
  .radium_mega.dl-subview li.dl-subviewopen > a,
  .radium_mega.dl-subview li.dl-subview > a {
    display: none;
  }

  /* line 1058, ../scss/mobile.scss */
  .radium_mega.dl-subview li.dl-subview,
  .radium_mega.dl-subview li.dl-subview .sub-menu,
  .radium_mega.dl-subview li.dl-subviewopen,
  .radium_mega.dl-subview li.dl-subviewopen > .sub-menu,
  .radium_mega.dl-subview li.dl-subviewopen > .sub-menu > li,
  .radium_mega.dl-subview li.dl-subviewopen > div > .sub-menu,
  .radium_mega.dl-subview li.dl-subviewopen > div > .sub-menu > li,
  .radium_mega.dl-subview li.dl-subviewopen > div > .sub-menu > li ul li {
    display: block;
  }

  /* line 1069, ../scss/mobile.scss */
  .radium_mega li > div [class*="column"] + [class*="column"]:last-child {
    float: none !important;
  }

  /* Dynamically added submenu outside of the menu context */
  /* line 1073, ../scss/mobile.scss */
  .main_menu > .sub-menu {
    position: absolute;
    width: 100%;
    top: 50px;
    left: 0;
    margin: 0;
  }

  /* Animation classes for moving out and in */
  /* line 1083, ../scss/mobile.scss */
  .radium_mega.dl-animate-out-1 {
    -webkit-animation: MenuAnimOut1 0.4s;
    -moz-animation: MenuAnimOut1 0.4s;
    animation: MenuAnimOut1 0.4s;
  }

  /* line 1089, ../scss/mobile.scss */
  .radium_mega.dl-animate-out-2 {
    -webkit-animation: MenuAnimOut2 0.3s ease-in-out;
    -moz-animation: MenuAnimOut2 0.3s ease-in-out;
    animation: MenuAnimOut2 0.3s ease-in-out;
  }

  /* line 1095, ../scss/mobile.scss */
  .radium_mega.dl-animate-out-3 {
    -webkit-animation: MenuAnimOut3 0.4s ease;
    -moz-animation: MenuAnimOut3 0.4s ease;
    animation: MenuAnimOut3 0.4s ease;
  }

  /* line 1101, ../scss/mobile.scss */
  .radium_mega.dl-animate-out-4 {
    -webkit-animation: MenuAnimOut4 0.4s ease;
    -moz-animation: MenuAnimOut4 0.4s ease;
    animation: MenuAnimOut4 0.4s ease;
  }

  /* line 1107, ../scss/mobile.scss */
  .radium_mega.dl-animate-out-5 {
    -webkit-animation: MenuAnimOut5 0.4s ease;
    -moz-animation: MenuAnimOut5 0.4s ease;
    animation: MenuAnimOut5 0.4s ease;
  }

  @-webkit-keyframes MenuAnimOut1 {
    50% {
      -webkit-transform: translateZ(-250px) rotateY(30deg);
    }
    75% {
      -webkit-transform: translateZ(-372.5px) rotateY(15deg);
      opacity: .5;
    }
    100% {
      -webkit-transform: translateZ(-500px) rotateY(0deg);
      opacity: 0;
    }
  }
  @-webkit-keyframes MenuAnimOut2 {
    100% {
      -webkit-transform: translateX(-100%);
      opacity: 0;
    }
  }
  @-webkit-keyframes MenuAnimOut3 {
    100% {
      -webkit-transform: translateZ(300px);
      opacity: 0;
    }
  }
  @-webkit-keyframes MenuAnimOut4 {
    100% {
      -webkit-transform: translateZ(-300px);
      opacity: 0;
    }
  }
  @-webkit-keyframes MenuAnimOut5 {
    100% {
      -webkit-transform: translateY(40%);
      opacity: 0;
    }
  }
  @-moz-keyframes MenuAnimOut1 {
    50% {
      -moz-transform: translateZ(-250px) rotateY(30deg);
    }
    75% {
      -moz-transform: translateZ(-372.5px) rotateY(15deg);
      opacity: .5;
    }
    100% {
      -moz-transform: translateZ(-500px) rotateY(0deg);
      opacity: 0;
    }
  }
  @-moz-keyframes MenuAnimOut2 {
    100% {
      -moz-transform: translateX(-100%);
      opacity: 0;
    }
  }
  @-moz-keyframes MenuAnimOut3 {
    100% {
      -moz-transform: translateZ(300px);
      opacity: 0;
    }
  }
  @-moz-keyframes MenuAnimOut4 {
    100% {
      -moz-transform: translateZ(-300px);
      opacity: 0;
    }
  }
  @-moz-keyframes MenuAnimOut5 {
    100% {
      -moz-transform: translateY(40%);
      opacity: 0;
    }
  }
  @keyframes MenuAnimOut1 {
    50% {
      transform: translateZ(-250px) rotateY(30deg);
    }
    75% {
      transform: translateZ(-372.5px) rotateY(15deg);
      opacity: .5;
    }
    100% {
      transform: translateZ(-500px) rotateY(0deg);
      opacity: 0;
    }
  }
  @keyframes MenuAnimOut2 {
    100% {
      transform: translateX(-100%);
      opacity: 0;
    }
  }
  @keyframes MenuAnimOut3 {
    100% {
      transform: translateZ(300px);
      opacity: 0;
    }
  }
  @keyframes MenuAnimOut4 {
    100% {
      transform: translateZ(-300px);
      opacity: 0;
    }
  }
  @keyframes MenuAnimOut5 {
    100% {
      transform: translateY(40%);
      opacity: 0;
    }
  }
  /* line 1239, ../scss/mobile.scss */
  .radium_mega.dl-animate-in-1 {
    -webkit-animation: MenuAnimIn1 0.3s;
    -moz-animation: MenuAnimIn1 0.3s;
    animation: MenuAnimIn1 0.3s;
  }

  /* line 1245, ../scss/mobile.scss */
  .radium_mega.dl-animate-in-2 {
    -webkit-animation: MenuAnimIn2 0.3s ease-in-out;
    -moz-animation: MenuAnimIn2 0.3s ease-in-out;
    animation: MenuAnimIn2 0.3s ease-in-out;
  }

  /* line 1251, ../scss/mobile.scss */
  .radium_mega.dl-animate-in-3 {
    -webkit-animation: MenuAnimIn3 0.4s ease;
    -moz-animation: MenuAnimIn3 0.4s ease;
    animation: MenuAnimIn3 0.4s ease;
  }

  /* line 1257, ../scss/mobile.scss */
  .radium_mega.dl-animate-in-4 {
    -webkit-animation: MenuAnimIn4 0.4s ease;
    -moz-animation: MenuAnimIn4 0.4s ease;
    animation: MenuAnimIn4 0.4s ease;
  }

  /* line 1263, ../scss/mobile.scss */
  .radium_mega.dl-animate-in-5 {
    -webkit-animation: MenuAnimIn5 0.4s ease;
    -moz-animation: MenuAnimIn5 0.4s ease;
    animation: MenuAnimIn5 0.4s ease;
  }

  @-webkit-keyframes MenuAnimIn1 {
    0% {
      -webkit-transform: translateZ(-500px) rotateY(0deg);
      opacity: 0;
    }
    20% {
      -webkit-transform: translateZ(-250px) rotateY(30deg);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: translateZ(0px) rotateY(0deg);
      opacity: 1;
    }
  }
  @-webkit-keyframes MenuAnimIn2 {
    0% {
      -webkit-transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes MenuAnimIn3 {
    0% {
      -webkit-transform: translateZ(300px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes MenuAnimIn4 {
    0% {
      -webkit-transform: translateZ(-300px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes MenuAnimIn5 {
    0% {
      -webkit-transform: translateY(40%);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      opacity: 1;
    }
  }
  @-moz-keyframes MenuAnimIn1 {
    0% {
      -moz-transform: translateZ(-500px) rotateY(0deg);
      opacity: 0;
    }
    20% {
      -moz-transform: translateZ(-250px) rotateY(30deg);
      opacity: 0.5;
    }
    100% {
      -moz-transform: translateZ(0px) rotateY(0deg);
      opacity: 1;
    }
  }
  @-moz-keyframes MenuAnimIn2 {
    0% {
      -moz-transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      -moz-transform: translateX(0px);
      opacity: 1;
    }
  }
  @-moz-keyframes MenuAnimIn3 {
    0% {
      -moz-transform: translateZ(300px);
      opacity: 0;
    }
    100% {
      -moz-transform: translateZ(0px);
      opacity: 1;
    }
  }
  @-moz-keyframes MenuAnimIn4 {
    0% {
      -moz-transform: translateZ(-300px);
      opacity: 0;
    }
    100% {
      -moz-transform: translateZ(0px);
      opacity: 1;
    }
  }
  @-moz-keyframes MenuAnimIn5 {
    0% {
      -moz-transform: translateY(40%);
      opacity: 0;
    }
    100% {
      -moz-transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes MenuAnimIn1 {
    0% {
      transform: translateZ(-500px) rotateY(0deg);
      opacity: 0;
    }
    20% {
      transform: translateZ(-250px) rotateY(30deg);
      opacity: 0.5;
    }
    100% {
      transform: translateZ(0px) rotateY(0deg);
      opacity: 1;
    }
  }
  @keyframes MenuAnimIn2 {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes MenuAnimIn3 {
    0% {
      transform: translateZ(300px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0px);
      opacity: 1;
    }
  }
  @keyframes MenuAnimIn4 {
    0% {
      transform: translateZ(-300px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0px);
      opacity: 1;
    }
  }
  @keyframes MenuAnimIn5 {
    0% {
      transform: translateY(40%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  /* line 1446, ../scss/mobile.scss */
  .main_menu > .sub-menu.dl-animate-in-1 {
    -webkit-animation: SubMenuAnimIn1 0.4s ease;
    -moz-animation: SubMenuAnimIn1 0.4s ease;
    animation: SubMenuAnimIn1 0.4s ease;
  }

  /* line 1452, ../scss/mobile.scss */
  .main_menu > .sub-menu.dl-animate-in-2 {
    -webkit-animation: SubMenuAnimIn2 0.3s ease-in-out;
    -moz-animation: SubMenuAnimIn2 0.3s ease-in-out;
    animation: SubMenuAnimIn2 0.3s ease-in-out;
  }

  /* line 1458, ../scss/mobile.scss */
  .main_menu > .sub-menu.dl-animate-in-3 {
    -webkit-animation: SubMenuAnimIn3 0.4s ease;
    -moz-animation: SubMenuAnimIn3 0.4s ease;
    animation: SubMenuAnimIn3 0.4s ease;
  }

  /* line 1464, ../scss/mobile.scss */
  .main_menu > .sub-menu.dl-animate-in-4 {
    -webkit-animation: SubMenuAnimIn4 0.4s ease;
    -moz-animation: SubMenuAnimIn4 0.4s ease;
    animation: SubMenuAnimIn4 0.4s ease;
  }

  /* line 1470, ../scss/mobile.scss */
  .main_menu > .sub-menu.dl-animate-in-5 {
    -webkit-animation: SubMenuAnimIn5 0.4s ease;
    -moz-animation: SubMenuAnimIn5 0.4s ease;
    animation: SubMenuAnimIn5 0.4s ease;
  }

  @-webkit-keyframes SubMenuAnimIn1 {
    0% {
      -webkit-transform: translateX(50%);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes SubMenuAnimIn2 {
    0% {
      -webkit-transform: translateX(100%);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes SubMenuAnimIn3 {
    0% {
      -webkit-transform: translateZ(-300px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes SubMenuAnimIn4 {
    0% {
      -webkit-transform: translateZ(300px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes SubMenuAnimIn5 {
    0% {
      -webkit-transform: translateZ(-200px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
      opacity: 1;
    }
  }
  @-moz-keyframes SubMenuAnimIn1 {
    0% {
      -moz-transform: translateX(50%);
      opacity: 0;
    }
    100% {
      -moz-transform: translateX(0px);
      opacity: 1;
    }
  }
  @-moz-keyframes SubMenuAnimIn2 {
    0% {
      -moz-transform: translateX(100%);
      opacity: 0;
    }
    100% {
      -moz-transform: translateX(0px);
      opacity: 1;
    }
  }
  @-moz-keyframes SubMenuAnimIn3 {
    0% {
      -moz-transform: translateZ(-300px);
      opacity: 0;
    }
    100% {
      -moz-transform: translateZ(0px);
      opacity: 1;
    }
  }
  @-moz-keyframes SubMenuAnimIn4 {
    0% {
      -moz-transform: translateZ(300px);
      opacity: 0;
    }
    100% {
      -moz-transform: translateZ(0px);
      opacity: 1;
    }
  }
  @-moz-keyframes SubMenuAnimIn5 {
    0% {
      -moz-transform: translateZ(-200px);
      opacity: 0;
    }
    100% {
      -moz-transform: translateZ(0);
      opacity: 1;
    }
  }
  @keyframes SubMenuAnimIn1 {
    0% {
      transform: translateX(50%);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes SubMenuAnimIn2 {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes SubMenuAnimIn3 {
    0% {
      transform: translateZ(-300px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0px);
      opacity: 1;
    }
  }
  @keyframes SubMenuAnimIn4 {
    0% {
      transform: translateZ(300px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0px);
      opacity: 1;
    }
  }
  @keyframes SubMenuAnimIn5 {
    0% {
      transform: translateZ(-200px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0);
      opacity: 1;
    }
  }
  /* line 1641, ../scss/mobile.scss */
  .main_menu > .sub-menu.dl-animate-out-1 {
    -webkit-animation: SubMenuAnimOut1 0.4s ease;
    -moz-animation: SubMenuAnimOut1 0.4s ease;
    animation: SubMenuAnimOut1 0.4s ease;
  }

  /* line 1647, ../scss/mobile.scss */
  .main_menu > .sub-menu.dl-animate-out-2 {
    -webkit-animation: SubMenuAnimOut2 0.3s ease-in-out;
    -moz-animation: SubMenuAnimOut2 0.3s ease-in-out;
    animation: SubMenuAnimOut2 0.3s ease-in-out;
  }

  /* line 1653, ../scss/mobile.scss */
  .main_menu > .sub-menu.dl-animate-out-3 {
    -webkit-animation: SubMenuAnimOut3 0.4s ease;
    -moz-animation: SubMenuAnimOut3 0.4s ease;
    animation: SubMenuAnimOut3 0.4s ease;
  }

  /* line 1659, ../scss/mobile.scss */
  .main_menu > .sub-menu.dl-animate-out-4 {
    -webkit-animation: SubMenuAnimOut4 0.4s ease;
    -moz-animation: SubMenuAnimOut4 0.4s ease;
    animation: SubMenuAnimOut4 0.4s ease;
  }

  /* line 1665, ../scss/mobile.scss */
  .main_menu > .sub-menu.dl-animate-out-5 {
    -webkit-animation: SubMenuAnimOut5 0.4s ease;
    -moz-animation: SubMenuAnimOut5 0.4s ease;
    animation: SubMenuAnimOut5 0.4s ease;
  }

  @-webkit-keyframes SubMenuAnimOut1 {
    0% {
      -webkit-transform: translateX(0%);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(50%);
      opacity: 0;
    }
  }
  @-webkit-keyframes SubMenuAnimOut2 {
    0% {
      -webkit-transform: translateX(0%);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(100%);
      opacity: 0;
    }
  }
  @-webkit-keyframes SubMenuAnimOut3 {
    0% {
      -webkit-transform: translateZ(0px);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(-300px);
      opacity: 0;
    }
  }
  @-webkit-keyframes SubMenuAnimOut4 {
    0% {
      -webkit-transform: translateZ(0px);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(300px);
      opacity: 0;
    }
  }
  @-webkit-keyframes SubMenuAnimOut5 {
    0% {
      -webkit-transform: translateZ(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(-200px);
      opacity: 0;
    }
  }
  @-moz-keyframes SubMenuAnimOut1 {
    0% {
      -moz-transform: translateX(0%);
      opacity: 1;
    }
    100% {
      -moz-transform: translateX(50%);
      opacity: 0;
    }
  }
  @-moz-keyframes SubMenuAnimOut2 {
    0% {
      -moz-transform: translateX(0%);
      opacity: 1;
    }
    100% {
      -moz-transform: translateX(100%);
      opacity: 0;
    }
  }
  @-moz-keyframes SubMenuAnimOut3 {
    0% {
      -moz-transform: translateZ(0px);
      opacity: 1;
    }
    100% {
      -moz-transform: translateZ(-300px);
      opacity: 0;
    }
  }
  @-moz-keyframes SubMenuAnimOut4 {
    0% {
      -moz-transform: translateZ(0px);
      opacity: 1;
    }
    100% {
      -moz-transform: translateZ(300px);
      opacity: 0;
    }
  }
  @-moz-keyframes SubMenuAnimOut5 {
    0% {
      -moz-transform: translateZ(0);
      opacity: 1;
    }
    100% {
      -moz-transform: translateZ(-200px);
      opacity: 0;
    }
  }
  @keyframes SubMenuAnimOut1 {
    0% {
      transform: translateX(0%);
      opacity: 1;
    }
    100% {
      transform: translateX(50%);
      opacity: 0;
    }
  }
  @keyframes SubMenuAnimOut2 {
    0% {
      transform: translateX(0%);
      opacity: 1;
    }
    100% {
      transform: translateX(100%);
      opacity: 0;
    }
  }
  @keyframes SubMenuAnimOut3 {
    0% {
      transform: translateZ(0px);
      opacity: 1;
    }
    100% {
      transform: translateZ(-300px);
      opacity: 0;
    }
  }
  @keyframes SubMenuAnimOut4 {
    0% {
      transform: translateZ(0px);
      opacity: 1;
    }
    100% {
      transform: translateZ(300px);
      opacity: 0;
    }
  }
  @keyframes SubMenuAnimOut5 {
    0% {
      transform: translateZ(0);
      opacity: 1;
    }
    100% {
      transform: translateZ(-200px);
      opacity: 0;
    }
  }
  /* No JS Fallback */
  /* line 1837, ../scss/mobile.scss */
  .no-js .main_menu .radium_mega {
    position: relative;
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
  }

  /* line 1845, ../scss/mobile.scss */
  .no-js .main_menu li .sub-menu {
    display: block;
  }

  /* line 1849, ../scss/mobile.scss */
  .no-js .main_menu li.dl-back {
    display: none;
  }

  /* line 1853, ../scss/mobile.scss */
  .no-js .main_menu li > a:not(:only-child) {
    background: rgba(0, 0, 0, 0.1);
  }

  /* line 1857, ../scss/mobile.scss */
  .no-js .main_menu li > a:not(:only-child):after {
    content: '';
  }

  /* line 1861, ../scss/mobile.scss */
  .main_menu button {
    border-right: 1px solid #dfdfdd;
    background-color: transparent;
  }
  /* line 1865, ../scss/mobile.scss */
  .main_menu button:hover:after {
    background-color: white;
    box-shadow: 0 5px 0 white, 0 10px 0 white, 0 15px 0 white;
  }

  /* line 1874, ../scss/mobile.scss */
  .main_menu button.dl-active {
    border-right: 1px solid black;
  }
  /* line 1876, ../scss/mobile.scss */
  .main_menu button.dl-active:after {
    background-color: white;
    box-shadow: 0 5px 0 white, 0 10px 0 white, 0 15px 0 white;
  }

  /* line 1885, ../scss/mobile.scss */
  .main_menu button:hover,
  .main_menu button.dl-active,
  .main_menu ul {
    background: #22272A;
  }

  /* line 1891, ../scss/mobile.scss */
  .main_menu li {
    border-bottom: 1px solid #363A3D;
  }

  /* line 1892, ../scss/mobile.scss */
  .main_menu li:last-child {
    border-bottom: none;
  }

  /* line 1895, ../scss/mobile.scss */
  table.responsive {
    margin-bottom: 0;
  }

  /* line 1897, ../scss/mobile.scss */
  .pinned {
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    width: 35%;
    overflow: hidden;
    overflow-x: scroll;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }

  /* line 1898, ../scss/mobile.scss */
  .pinned table {
    border-right: none;
    border-left: none;
    width: 100%;
  }

  /* line 1899, ../scss/mobile.scss */
  .pinned table th, .pinned table td {
    white-space: nowrap;
  }

  /* line 1900, ../scss/mobile.scss */
  .pinned td:last-child {
    border-bottom: 0;
  }

  /* line 1902, ../scss/mobile.scss */
  div.table-wrapper {
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    border-right: 1px solid #ccc;
  }

  /* line 1903, ../scss/mobile.scss */
  div.table-wrapper div.scrollable {
    margin-left: 35%;
  }

  /* line 1904, ../scss/mobile.scss */
  div.table-wrapper div.scrollable {
    overflow: scroll;
    overflow-y: hidden;
  }

  /* line 1906, ../scss/mobile.scss */
  table.responsive td, table.responsive th {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
  }

  /* line 1907, ../scss/mobile.scss */
  table.responsive th:first-child, table.responsive td:first-child, table.responsive td:first-child, table.responsive.pinned td {
    display: none;
  }
}
